import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {
    addPackageApi, postBlogApi, pullBlogApi,
    pullPackagesApi,
    pullTransactionsApi, pullTransactionsByUserIdApi,
    purchasePackageApi,
    purchaseServiceApi
} from "../../services";
import {  useNavigate } from 'react-router-dom';
import {description} from "../../_mock/text";

// ----------------------------------------------------------------------


const initialState = {
    isLoadingBlog: false,
    error: null,
    blogs:[{title:" ",content:"",author:""}],
    msg: null,
};

const slice = createSlice({
    name: 'blog',
    initialState,
    reducers:{
        // START LOADING
        startLoading(state) {
            state.isLoadingBlog = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoadingBlog = false;
            state.error = action.payload;
            state.error=null
        },
        //success
        success(state,action){
            state.isLoadingBlog = false;
            state.msg = action.payload;

        },
        //remove success
        removeSuccess(state,action){
            state.isLoadingBlog=false;
           state.msg=null
        },
        //remove Error
        removeError(state,action){
            state.isLoadingBlog=false;
            {state.error=null}
        } ,
        pullBlogReducer(state, action){
            state.blogs =action.payload
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    startLoading,
    hasError,
    pullBlogReducer,
    removeSuccess,
    removeError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function  postBlog(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await postBlogApi(data1);
            if(response.data.code === 200){
                dispatch(slice.actions.success(" successfully"));
                dispatch(slice.actions.removeSuccess())
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.removeError())
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function errorFunction(msg) {
    dispatch(slice.actions.hasError(msg))
    dispatch(slice.actions.removeSuccess())
}

//==========================================================

export function  pullBlogs(operatorId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullBlogApi(operatorId);
            if(response.data.code === 200){
                dispatch(slice.actions.success(" successfully"));
                dispatch(slice.actions.pullBlogReducer(response.data.blog));
                dispatch(slice.actions.removeSuccess())
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.removeError())
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}