import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {addPackageApi, editPackageApi, pullPackagesApi} from "../../services";
import {useNavigate} from 'react-router-dom';
import {description} from "../../_mock/text";
import sampleCover from "../../assets/FX/sampleCover.avif";

// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    packages: [],
    signalPackages: [],
    trainingPackages: [],
    selectedPackage: {},
    courses: [
        {
            id: "123",
            cover: "/sd/dsfs",
            instructor: "pemachele and John Jacksonm",
            description: "Understand the interconnectedness of the global capital markets with a " +
                "practical emphasis on trading. By the end of this specialization, the interweaving " +
                "of several capital markets will be apparent to students keen to learn about its mechanics, " +
                "as well as those investors whose strengths are limited to their existing trading knowledge.",
            title: "Practical Guide to Trading Specialization",
            sections: [
                {
                title: "Section title",
                description: "Description",
                order: 1,
                id: "123",
                course_id: "123",
                subSections: [{
                    title: "subsesction title",
                    order: 1,
                    description: "\"practical emphasis on" +
                        " trading. By the end of this specialization, the interweaving \" +\n" +
                        "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                        "            \"as well as those investors whose strengths are l",
                    video: "x89xa1v",
                    section_id: "123",
                },
                    {
                        title: "subsesction title 2 ",
                        order: 2,
                        description: "\"practical emphasis on" +
                            " trading. By the end of this specialization, the interweaving \" +\n" +
                            "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                            "            \"as well as those investors whose strengths are l",
                        video: "x89xa1v",
                        section_id: "123",
                    }
                ]
            },
                {
                    title: "Section title 2",
                    description: "Description",
                    order: 2,
                    id: "123",
                    course_id: "123",
                    subSections: [{
                        title: "subsesction title",
                        order: 1,
                        description: "\"practical emphasis on" +
                            " trading. By the end of this specialization, the interweaving \" +\n" +
                            "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                            "            \"as well as those investors whose strengths are l",
                        video: "x89xa1v",
                        section_id: "123",
                    },
                        {
                            title: "subsesction title 2 ",
                            order: 2,
                            description: "\"practical emphasis on" +
                                " trading. By the end of this specialization, the interweaving \" +\n" +
                                "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                                "            \"as well as those investors whose strengths are l",
                            video: "x89xa1v",
                            section_id: "123",
                        }
                    ]

                },
                {
                    title: "Section title 2",
                    description: "Description",
                    order: 2,
                    id: "12345",
                    course_id: "123",
                    subSections: [{
                        title: "subsesction title",
                        order: 1,
                        description: "\"practical emphasis on" +
                            " trading. By the end of this specialization, the interweaving \" +\n" +
                            "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                            "            \"as well as those investors whose strengths are l",
                        video: "x89xa1v",
                        section_id: "123",
                    },
                        {
                            title: "subsesction title 2 ",
                            order: 2,
                            description: "\"practical emphasis on" +
                                " trading. By the end of this specialization, the interweaving \" +\n" +
                                "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                                "            \"as well as those investors whose strengths are l",
                            video: "x89xa1v",
                            section_id: "123",
                        }
                    ]

                },
            ],


        },

    ],
    sections: [{
        title: "Section title",
        description: "Description",
        order: 1,
        id: "123",
        course_id: "1234"
    },
        {
            title: "Section title 2",
            description: "Description",
            order: 2,
            id: "1234",
            course_id: "1234"

        }

    ],
    subSections: [{
        title: "subsesction title",
        order: 1,
        description: "\"practical emphasis on" +
            " trading. By the end of this specialization, the interweaving \" +\n" +
            "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
            "            \"as well as those investors whose strengths are l",
        video: "x89xa1v",
        section_id: "123",
    },
        {
            title: "subsesction title 2 ",
            order: 2,
            description: "\"practical emphasis on" +
                " trading. By the end of this specialization, the interweaving \" +\n" +
                "            \"of several capital markets will be apparent to students keen to learn about its mechanics, \" +\n" +
                "            \"as well as those investors whose strengths are l",
            video: "x89xa1v",
            section_id: "123",
        }
    ],
    singleCourse:{levels:[]}
};

const slice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        //success
        success(state, action) {
            state.isLoading = false;
            state.msg = action.payload;
            setTimeout(() => {
                state.msg = null
            }, 1000)
        },

        // PULL PACKAGES
        getPackages(state, action) {
            state.packages = action.payload;
        },
        getSignalPackages(state, action) {
            state.signalPackages = action.payload;
        },
        getTrainingPackages(state, action) {
            state.trainingPackages = action.payload;
        },
        getSelectedPackage(state, action) {
            state.singlePackage = action.payload
        },
        getCourses(state, action) {
            state.courses = action.payload;
        },
        getSections(state, action) {
            state.sections = action.payload;
        },
        getSubSection(state, action) {
            state.subSections = action.payload;
        },
        getSpecificCourseSlice(state, action) {
            state.singleCourse = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCourses,
    getSections,
    getSpecificCourseSlice,
    getSubSection,
    getPackages,
    getSignalPackages,
    getTrainingPackages,
    startLoading,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function pullPackages(operator_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(operator_id);
            if (response.data.code === 200) {
                dispatch(slice.actions.getPackages(response.data.services));
                dispatch(slice.actions.getSignalPackages(response.data.services.filter((item) => item.type === "signal")))
                dispatch(slice.actions.getTrainingPackages(response.data.services.filter((item) => item.type !== "signal")))
                dispatch(slice.actions.endLoading())
            } else {
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function addPackage(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addPackageApi(data1);
            if (response.data.code === 200) {
                dispatch(slice.actions.success("package added successfully"));
                dispatch(slice.actions.endLoading())
            } else {
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function editPackage(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await editPackageApi(data1);
            if (response.data.code === 200) {
                dispatch(slice.actions.success("package Edited successfully"));
                dispatch(slice.actions.endLoading())

            } else {
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// -------------------------

export function pullSelectedPackageApi(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(data1.operator_id);
            if (response.data.code === 200) {
                dispatch(slice.actions.getPackages(response.data.services));
                dispatch(slice.actions.getSelectedPackage(response.data.services.filter((item) => item.service_id === data1.service_id)))
                dispatch(slice.actions.endLoading())
                console.log(response.data.services.filter((item) => item.service_id === data1.service_id))
            } else {
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}


export function addCourse(data) {
    dispatch(slice.actions.getCourses(data))
}

export function addSection(data) {
    dispatch(slice.actions.getSections(data))
}

export function getSpecificCourse(data,courseId) {
    const courses = data;
    let kk= courses.find((item)=>item.course_id === courseId )
    let levels = [...kk.levels];
    try{
        levels.sort((a,b)=>(parseInt(a.order) - parseInt( b.order)));
        kk = JSON.parse(JSON.stringify(kk))
        kk.levels = [... levels];
        console.log("singg",kk)
        dispatch(slice.actions.getSpecificCourseSlice(kk));

    }catch (e) {
        dispatch(slice.actions.getSpecificCourseSlice(courses.find((item)=>item.course_id === courseId )));
        console.log('erro for sort r',e)
    }
}

export function addSubSection(data) {
    dispatch(slice.actions.getSubSection(data))
}




