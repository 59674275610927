import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'TRAINING AND MENTORSHIP',
    icon: <PlanFreeIcon />,
    price: 750,
    caption: 'online',
    lists: [
      { text: 'IntelligenceFx Substratum Series', isAvailable: true },
      { text: '  Traders Strategy Guidebook', isAvailable: true },
      { text: 'One to One Session (via zoom)', isAvailable: true },
      { text: ' Weekend, Mid-week and Monthly Live Webinar', isAvailable: true},
      { text: ' Mid-week Market Breakdown & Review', isAvailable: true },
      { text: 'Access to Community', isAvailable: true },
      { text: 'IntelligenceFx Meet Up', isAvailable: true },
      { text: 'Certificate', isAvailable: true },
      { text: 'Lifetime Mentorship', isAvailable: true },
      { text: 'Access to Signal and Setup for 3 Months', isAvailable: true },
      { text: ' Access to Recorded and Live Bootcamp Series', isAvailable: true },
    ],
    labelAction: 'current plan',
  },
  {
    subscription: 'IFX BOOTCAMP & MENTORSHIP',
    icon: <PlanStarterIcon />,
    price: 299,
    caption: 'saving $300',
    lists: [
      { text: 'Access to Student Dashboard', isAvailable: true },
      { text: 'Recorded Bootcamp Series', isAvailable: true },
      { text: ' Traders Strategy Guidebook', isAvailable: true },
      { text: 'Questions and Answers Sessions', isAvailable: true },
      { text: ' Weekend Midweek & Monthly Live Webinar', isAvailable: true},
      { text: '  Weekend Market Breakdown', isAvailable: true },
      { text: '  Mid-Week Market Breakdown', isAvailable: true },
      { text: ' IntelligenceFX Meetup', isAvailable: true },
      { text: '  Lifetime Access to Community & Mentorship', isAvailable: true },
    ],
    labelAction: 'Start now',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: 1500,
    caption: 'Physical',
    lists: [
      { text: 'IntelligenceFx Substratum Series', isAvailable: true },
      { text: '  Traders Strategy Guidebook', isAvailable: true },
      { text: 'One to One Session (physical)', isAvailable: true },
      { text: ' Weekend, Mid-week and Monthly Live Webinar', isAvailable: true},
      { text: ' Mid-week Market Breakdown & Review', isAvailable: true },
      { text: 'Access to Community', isAvailable: true },
      { text: 'IntelligenceFx Meet Up', isAvailable: true },
      { text: 'Certificate', isAvailable: true },
      { text: 'Lifetime Mentorship', isAvailable: true },
      { text: 'Access to Signal and Setup for 3 Months', isAvailable: true },
      { text: ' Access to Recorded and Live Bootcamp Series', isAvailable: true },
    ],
    labelAction: 'choose premium',
  },
];
