import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {addPackageApi, editPackageApi, pullPackagesApi} from "../../services";
import {  useNavigate } from 'react-router-dom';
import {description} from "../../_mock/text";

// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    packages:[],
    signalPackages:[],
    trainingPackages:[],
    selectedPackage:{},
    packageCourses:[]
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        //success
        success(state,action){
            state.isLoading = false;
            state.msg = action.payload;
            setTimeout(()=>{state.msg=null},1000)
        },

        // PULL PACKAGES
        getPackages(state, action) {
            state.packages = action.payload;
        },
        getSignalPackages(state,action){
            state.signalPackages=action.payload;
        },
        getTrainingPackages(state,action){
            state.trainingPackages=action.payload;
        },
         getSelectedPackage (state,action){
            state.singlePackage=action.payload
         },
        getPackageCoursesSlices (state,action){
            state.packageCourses=action.payload
        }

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getPackages,
   getPackageCoursesSlices,
    getSignalPackages,
    getTrainingPackages,
    startLoading,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function  pullPackages(operator_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(operator_id);
            if(response.data.code === 200){
                dispatch(slice.actions.getPackages(response.data.services));
                dispatch(slice.actions.getSignalPackages( response.data.services.filter((item)=>item.type ==="signal and setups")))
                dispatch(slice.actions.getTrainingPackages(response.data.services.filter((item)=>item.type!=="signal and setups")))
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function  addPackage(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addPackageApi(data1);
            if(response.data.code === 200){
                dispatch(slice.actions.success("package added successfully"));
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function  editPackage(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await editPackageApi(data1);
            if(response.data.code === 200){
                dispatch(slice.actions.success("package Edited successfully"));
                dispatch(slice.actions.endLoading())

            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}
// -------------------------

export function  pullSelectedPackageApi(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(data1.operator_id);
            if(response.data.code === 200){
                dispatch(slice.actions.getPackages(response.data.services));
                dispatch(slice.actions.getSelectedPackage( response.data.services.filter((item)=>item.service_id ===data1.service_id)))
                dispatch(slice.actions.endLoading())
                console.log(response.data.services.filter((item)=>item.service_id ===data1.service_id))
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}


export function getPackageCourses(data) {
    dispatch(slice.actions.getPackageCoursesSlices(data))
}



