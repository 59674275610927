import axios from 'axios'


// const url = "https://apifx.invict.site/api"
// const url = "http://23.20.122.193/api"
const url = "https://fxapi.invict.site/api"
export const mainurl = url;

export const signin = async (email, password) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 100,
            code: 101,
            data: {
              email,
              password

            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullPackagesApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 114,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullUsersApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 115,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullStaffApi = async () => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 111,
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addStaffApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 110,
            data: data1
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const getStaffAvailabilityApi = async (staff_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 116,
            data: {
              staff_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addStaffAvailabilityApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 112,
            data: data1
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const getAppointmentUserApi = async () => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 112,
            data: data1
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const getBookingsApi = async () => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 123,

          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addPackageApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 110,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}

export const editPackageApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 123,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addCourseToPackageApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 127,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullTransactionsApi = async (operator_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 116,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;
  } catch (error) {
    console.log(error)
  }
}


export const pullTransactionsByUserIdApi = async (operator_id, user_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 117,
            data: {
              operator_id,
              user_id
            }
          }
        )
      }
    )
    return response;
  } catch (error) {
    console.log(error)
  }
}


export const postBlogApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 118,
            data: {
              ...data1
            }
          })
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullSessionStatsApi = async (operator_id, user_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 122,
            data: {
              operator_id,
              user_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullBlogApi = async (operator_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 119,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


//api to send multipart
export const pictureApi = async (data1) => {

  try {
    const data = await axios({
      method: "post",
      url: url,
      data: data1,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return data
  } catch (e) {

  }

};


export const sendNewsletterApi = async (operator_id, content, user_type, subject) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 121,
            data: {
              operator_id,
              content,
              user_type,
              subject
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}

export const videoUploadApi = async (data1) => {
  try {
    const data = await axios({
      method: "post",
      url: url,
      data: data1,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return data
  } catch (e) {

  }

};

export const pullVideosApi = async (operator_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 124,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullCoursesApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 125,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullServiceCoursesApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 133,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullCategorizedVideosApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 136,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deleteServiceCoursesApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 128,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const markAssignmentApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 132,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullAssignmentsApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 131,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addLevelApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 126,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const editLevelApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 141,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addCategoryApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 134,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const categorizeApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 137,
            data: {
              ...data1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deletePackageApi = async (operator_id, service_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 138,
            data: {
              operator_id,
              service_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deleteBlogApi = async (operator_id, blog_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 139,
            data: {
              operator_id,
              blog_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullCategoriesApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 135,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addCustomPurchaseApi = async (details) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 145,
            data: {
              ...details
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deleteCourseApi = async (details) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 142,
            data: {
              ...details
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deleteLevelApi = async (details) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 143,
            data: {
              ...details
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const deleteSubLevelApi = async (details) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 144,
            data: {
              ...details
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addVideoModifiedApi = async (operator_id, url1, category_id, description, title) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 146,
            data: {
              operator_id,
              title,
              description,
              category_id,
              url: url1
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullModifiedVideoApi = async (operator_id) => {
  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 148,
            data: {
              operator_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addResourceApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 147,
            data: {
              ...data1
            }
          })
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const pullMemberStatsApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 151,
            data: {
              operator_id
            }
          })
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}





export const pullMembersByMemberTypeApi = async (operator_id,member_type) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 152,
            data: {
              operator_id,
              member_type
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const changeMemberTypePromoApi = async (operator_id,ids,promo_type) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 153,
            data: {
              operator_id,
              promo_type,
              ids
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const resetDeviceApi = async (operator_id,user_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 149,
            data: {
              operator_id,
              user_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const addSubscriptionApi = async (operator_id,ids,days) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 157,
            data: {
              operator_id,
              ids,
              days
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullMembersCategorisedApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 154,
            data: {
              operator_id,
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const pullResourcesApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 155,
            data: {
              operator_id,
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}




export const addSignalTipApi = async (operator_id,content) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 158,
            data: {
              operator_id,
              content

            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}




export const getSignalTipApi = async (operator_id,page_number) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 159,
            data: {
              operator_id,
              page_number
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const sendNotificationApi = async (operator_id,ids,title,body,type) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 160,
            data: {
              operator_id,
              ids,
              title,
              body,
              type
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}





export const deleteResourceApi = async (operator_id,resource_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 162,
            data: {
              operator_id,
              resource_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const deleteVideoApi = async (operator_id,video_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 161,
            data: {
              operator_id,
              video_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}





export const createUserAffiliateApi = async (operator_id,user_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 164,
            data: {
              operator_id,
              user_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}






export const getInfluencersApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 165,
            data: {
              operator_id,
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}




export const getReferalsApi = async (operator_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 163,
            data: {
              operator_id,
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}






export const editStaffApi = async (data1) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 125,
            data:data1
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}







export const addDiscountApi = async (operator_id,user_ids,appointment_discount,tradeclub_discount) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 150,
            code: 112,
            data: {
              operator_id,
              user_ids,
              appointment_discount,
              tradeclub_discount
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const getUserDiscountApi = async (operator_id,user_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 150,
            code: 114,
            data: {
              operator_id,
              user_id,
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const disableStaffApi = async (staff_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 126,
            data: {
              staff_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const disableAvailabilityApi = async (availability_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 140,
            code: 127,
            data: {
              availability_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}




export const makePackageDiscountApi = async (user_ids,service_discounts) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 150,
            code: 116,
            data: {
              user_ids,
              service_discounts
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}





export const makeSignupDiscountApi = async (service_discounts) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 150,
            code: 117,
            data: {
              service_discounts
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const sendEmailApi = async (operator_id,emails,template,subject) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 168,
            data: {
              operator_id,
              emails,
              template,
              subject
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const deleteSignalApi = async (operator_id,signal_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 169,
            data: {
              operator_id,
              signal_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}



export const removeUserPackageApi = async (operator_id,user_id) => {

  try {
    const response = await axios({
        method: 'post',
        url: url,
        data: JSON.stringify(
          {
            api: 110,
            code: 170,
            data: {
              operator_id,
              user_id
            }
          }
        )
      }
    )
    return response;

  } catch (error) {
    console.log(error)
  }
}