import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../store';

// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    videos:{list:[]},
};

const slice = createSlice({
    name: 'videos',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        //success
        success(state, action) {
            state.isLoading = false;
            state.msg = action.payload;
        },
        getVideosSlice(state, action) {
            state.videos = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getVideosSlice,
    startLoading,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------


export function getVideos(data) {
    dispatch(slice.actions.getVideosSlice(data))
}




