import {Suspense, lazy} from 'react';
import {Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {PATH_AFTER_LOGIN} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register/>
            </GuestGuard>
          ),
        },
        {path: 'login-unprotected', element: <Login/>},
        // {path: 'register-unprotected', element: <Register/>},
        // {path: 'reset-password', element: <ResetPassword/>},
        // {path: 'verify', element: <VerifyCode/>},
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
        {path: 'app', element: <GeneralApp/>},
        // {path: 'members', element: <Member/>},
        {path: 'members', element: <UserList/>},
        {path: 'news-letter', element: <NewsLetter/>},
        {path: 'trade-room', element: <TradeRoom/>},
        {path: 'email', element: <Email/>},
        {path: 'notification', element: <Notification/>},
        {path: 'transactions', element: <Transactions/>},
        // {path: 'videos/all', element: <Videos/>},
        // {path: 'videos/single/:videoId', element: <VideoView/>},
        {
          path: 'videos',
          children: [
            {element: <Navigate to="/dashboard/videos/all" replace/>, index: true},
            {path: 'all', element: <Videos/>},
            {path: 'categories', element: <VideoCategories/>},
            {path: 'categories/:categoryId', element: <Videos/>},
            {path: 'add', element: <UploadVideo/>},
            {path: 'single/:videoId', element: <VideoView/>},
          ]
        },
        {
          path: 'resources',
          children: [
            {element: <Navigate to="/dashboard/resources/all" replace/>, index: true},
            {path: 'all', element: <Resources/>},
            {path: 'add', element: <AddResource/>},
          ]
        },
        {
          path: 'appointment',
          children: [
            {element: <Navigate to="/dashboard/appointment/staffs" replace/>, index: true},
            {path: 'staffs', element: <StaffList/>},
            {path: 'users', element: <AppointmentUserList/>},
            {path: 'bookings', element: <BookingList/>}
          ]
        },

        {
          path: 'affiliate',
          children: [
            {element: <Navigate to="/dashboard/affiliate/influencers" replace/>, index: true},
            {path: 'influencers', element: <Influencers/>},
            {path: 'referals', element: <Referals/>},

          ]
        },

        {
          path: 'new-users',
          children: [
            {element: <Navigate to="/dashboard/new-users/technical" replace/>, index: true},
            {path: 'technical', element: <NewUserList/>},
            {path: 'psychological', element: <NewUserList/>},
            {path: 'trading-room', element: <NewUserList/>}
          ]
        },

        {
          path: 'packages',
          children: [
            {element: <Navigate to="/dashboard/packages/all" replace/>, index: true},
            {path: 'all', element: <Packages/>},
            {path: 'add', element: <AddPackage/>},
            {path: 'edit/:packageId', element: <AddPackage/>}
          ]
        },
        {
          path: 'courses',
          children: [
            {element: <Navigate to="/dashboard/courses/all" replace/>, index: true},
            {path: 'all', element: <Courses/>},
            {path: 'add', element: <AddCourse/>},
            {path: 'assignments', element: <Assignments/>},
            {path: 'single/:courseId', element: <CourseView/>},
            {path: 'edit/:courseId', element: <EditCourseView/>},
          ]
        },
        // {path: 'packages/all ', element: <Packages/>},
        {path: 'analytics', element: <GeneralAnalytics/>},

        {path: 'banking', element: <GeneralBanking/>},
        {path: 'booking', element: <GeneralBooking/>},

        {
          path: 'e-commerce',
          children: [
            {element: <Navigate to="/dashboard/e-commerce/shop" replace/>, index: true},
            // {path: 'shop', element: <EcommerceShop/>},
            // {path: 'product/:name', element: <EcommerceProductDetails/>},
            // {path: 'list', element: <EcommerceProductList/>},
            // {path: 'product/new', element: <EcommerceProductCreate/>},
            // {path: 'product/:name/edit', element: <EcommerceProductCreate/>},
            // {path: 'checkout', element: <EcommerceCheckout/>},
            // {path: 'invoice', element: <EcommerceInvoice/>},
          ],
        },
        {
          path: 'user',
          children: [
            {element: <Navigate to="/dashboard/user/profile" replace/>, index: true},
            {path: 'profile', element: <UserProfile/>},
            {path: ':userId', element: <UserAccount/>},
            {path: 'cards', element: <UserCards/>},
            {path: 'list', element: <UserList/>},
            {path: 'new', element: <UserCreate/>},
            {path: ':name/edit', element: <UserCreate/>},
            {path: 'account', element: <UserAccount/>},
          ],
        },
        {
          path: 'blog',
          children: [
            {element: <Navigate to="/dashboard/blog/posts" replace/>, index: true},
            {path: 'posts', element: <BlogPosts/>},
            {path: 'post/:title', element: <BlogPost/>},
            {path: 'new-post', element: <BlogNewPost/>},
          ],
        },
        {
          path: 'mail',
          children: [
            {element: <Navigate to="/dashboard/mail/all" replace/>, index: true},
            {path: 'label/:customLabel', element: <Mail/>},
            {path: 'label/:customLabel/:mailId', element: <Mail/>},
            {path: ':systemLabel', element: <Mail/>},
            {path: ':systemLabel/:mailId', element: <Mail/>},
          ],
        },
        {
          path: 'chat',
          children: [
            {element: <Chat/>, index: true},
            // {path: 'new', element: <Chat/>},
            // {path: ':conversationKey', element: <Chat/>},
          ],
        },
        // {path: 'calendar', element: <Calendar/>},
        // {path: 'kanban', element: <Kanban/>},
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <MainLayout/>,
      children: [
        {path: 'coming-soon', element: <ComingSoon/>},
        {path: 'maintenance', element: <Maintenance/>},
        {path: 'pricing', element: <Pricing/>},
        {path: 'payment', element: <Payment/>},
        {path: '500', element: <Page500/>},
        {path: '404', element: <NotFound/>},
        {path: '*', element: <Navigate to="/404" replace/>},
      ],
    },

    {
      path: '/',
      element: <MainLayout/>,
      children: [
        {element: <Login/>, index: true},
        // {path: 'about-us', element: <About/>},
        // {path: 'contact-us', element: <Contact/>},
        // {path: 'faqs', element: <Faqs/>},
      ],
    },

    {path: '*', element: <Navigate to="/404" replace/>},
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));


// custom
// const Member = Loadable(lazy(() => import('../pages/dashboard/Members')));
const Transactions = Loadable(lazy(() => import('../pages/dashboard/Transactions')));
const Packages = Loadable(lazy(() => import('../pages/dashboard/Packages')));
const AddPackage = Loadable(lazy(() => import('../pages/dashboard/AddPackage')));
const Videos = Loadable(lazy(() => import('../pages/dashboard/Videos')));
const VideoCategories = Loadable(lazy(() => import('../pages/dashboard/VideoCategories')));
const UploadVideo = Loadable(lazy(() => import('../pages/dashboard/UploadVideo')));
const VideoView = Loadable(lazy(() => import('../pages/dashboard/VideoView')));
const NewsLetter = Loadable(lazy(() => import('../pages/dashboard/NewsLetter')));
const Email = Loadable(lazy(() => import('../pages/dashboard/Email')));
const Notification = Loadable(lazy(() => import('../pages/dashboard/Notification')));
const AddCourse = Loadable(lazy(() => import('../pages/dashboard/AddCourse')));
const Courses = Loadable(lazy(() => import('../pages/dashboard/Courses')));
const CourseView = Loadable(lazy(() => import('../pages/dashboard/CourseView')));
const EditCourseView = Loadable(lazy(() => import('../pages/dashboard/EditCourse')));
const Assignments = Loadable(lazy(() => import('../pages/dashboard/Assignments')));
const StaffList = Loadable(lazy(() => import('../pages/dashboard/StaffList')))
const AppointmentUserList = Loadable(lazy(() => import('../pages/dashboard/AppointmentUserList')))
const BookingList = Loadable(lazy(() => import('../pages/dashboard/BookingList')))
const AddResource = Loadable(lazy(() => import('../pages/dashboard/AddResource')))
const NewUserList = Loadable(lazy(() => import('../pages/dashboard/NewUserList')));
const Resources = Loadable(lazy(() => import('../pages/dashboard/Resources')));
const TradeRoom = Loadable(lazy(() => import('../pages/dashboard/TradeRoom')));
const Influencers = Loadable(lazy(() => import('../pages/dashboard/Influencers')));
const Referals = Loadable(lazy(() => import('../pages/dashboard/Referals')));



// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
