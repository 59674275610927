import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {addPackageApi, pullPackagesApi, pullUsersApi} from "../../services";
import {  useNavigate } from 'react-router-dom';
import {description} from "../../_mock/text";

// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    members:[{full_name: "Peter Machele", phone_number: "076542344", email: "peter@gmail.com",status:"member"}],
    selectedUser:{},
};

const slice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        //success
        success(state,action){
            state.isLoading = false;
            state.msg = action.payload;
            setTimeout(()=>{state.msg=null},1000)
        },

        // PULL users
        getMembers(state, action) {
            state.members = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getMembers,
    startLoading,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function  pullMembers(operator_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullUsersApi(operator_id);
            if(response.data.code === 200){
                console.log(response.data.users)
                dispatch(slice.actions.getMembers(response.data.users));
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

