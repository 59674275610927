// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import TimelineIcon from '@mui/icons-material/Timeline';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ShareIcon from '@mui/icons-material/Share';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  newsLetter: getIcon('ic_mail'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  members:getIcon('ic_users'),
  transactions:getIcon('ic_analytics'),
  packages:getIcon('ic_ecommerce'),
  videos:getIcon('ic_kanban'),
  courses:getIcon('ic_books')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: <GroupIcon />,
        children:[
          { title: 'Techinical course', path: PATH_DASHBOARD.users.technical },
          { title: 'Psychological course', path: PATH_DASHBOARD.users.psychological },
          { title: 'Live trading room', path: PATH_DASHBOARD.users.trading_room }
        ]
      },
      { title: 'Members', path: PATH_DASHBOARD.general.members, icon: <GroupAddOutlinedIcon />},
      { title: 'News letter', path: PATH_DASHBOARD.general.newsLetter, icon:<MarkAsUnreadIcon /> },
      { title: 'Email', path: PATH_DASHBOARD.general.email, icon:<MailOutlinedIcon />},
      { title: 'Trade Room', path: PATH_DASHBOARD.general.tradeRoom, icon:<TimelineIcon/>},
      // { title: 'Notification', path: PATH_DASHBOARD.general.notification, icon:<NotificationAddIcon/>},
      { title: 'Transactions', path: PATH_DASHBOARD.general.transactions, icon: <SignalCellularAltOutlinedIcon/> },
      { title: 'Packages',
        path: PATH_DASHBOARD.packages.root,
        icon: ICONS.packages,
        children:[
          { title: 'All packages', path: PATH_DASHBOARD.packages.all },
          { title: 'Add packages', path: PATH_DASHBOARD.packages.add }
        ]
       },
      // { title: 'Videos', path: PATH_DASHBOARD.general.videos.root, icon: ICONS.videos },
      { title: 'Videos',
        path: PATH_DASHBOARD.videos.root,
        icon: <VideoSettingsIcon />,
        children:[
          { title: 'All videos', path: PATH_DASHBOARD.videos.all },
          { title: 'Video Categories', path: PATH_DASHBOARD.videos.categories },
          { title: 'Add videos', path: PATH_DASHBOARD.videos.add }
        ]
      },

      { title: 'Resources',
        path: PATH_DASHBOARD.resource.root,
        icon: ICONS.videos,
        children:[
          { title: 'All resources', path: PATH_DASHBOARD.resource.all },
         { title: 'Add resource', path: PATH_DASHBOARD.resource.add }
        ]
      },

      { title: 'Affiliate',
        path: PATH_DASHBOARD.affiliate.root,
        icon:<ShareIcon />,
        children:[
          { title: 'Influencers', path: PATH_DASHBOARD.affiliate.influencers },
          { title: 'Referals', path: PATH_DASHBOARD.affiliate.referals }
        ]
      },

      { title: 'Courses',
        path: PATH_DASHBOARD.courses.root,
        icon: ICONS.courses,
        children:[
          { title: 'All Courses', path: PATH_DASHBOARD.courses.all },
          { title: 'Add Course', path: PATH_DASHBOARD.courses.add },
          { title: 'Assignments', path: PATH_DASHBOARD.courses.assignments },
        ]
      },

      { title: 'Appointment',
        path: PATH_DASHBOARD.appointments.root,
        icon: <TimelineIcon />,
        children:[
          { title: 'Staffs', path: PATH_DASHBOARD.appointments.staffs },
          { title: 'Booking', path: PATH_DASHBOARD.appointments.bookings },
        ]
      },
      // { title: 'UserList', path: PATH_DASHBOARD.user.list, icon: ICONS.videos },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         // { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         // { title: 'list', path: PATH_DASHBOARD.user.list },
  //         // { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         // { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         // { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },
  //
  //     // MANAGEMENT : E-COMMERCE
  //     // {
  //     //   title: 'e-commerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
  //     //   ],
  //     // },
  //
  //     MANAGEMENT : BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          // { title: 'post', path: PATH_DASHBOARD.blog.postById },
          { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
