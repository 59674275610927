import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import signinReducer from "./slices/signin";
import packageReducer from "./slices/package";
import memberReducer from "./slices/member";
import transactionReducer from "./slices/transaction";
import blogReducer from "./slices/blog";
import coursesReducer from "./slices/courses";
import videoReducer from "./slices/video";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  signin:signinReducer,
  package:packageReducer,
  kanban: kanbanReducer,
  transaction:transactionReducer,
  member:memberReducer,
  blog:blogReducer,
  course:coursesReducer,
  video:videoReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
