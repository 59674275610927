import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {signin} from "../../services";
import {  useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    user: null,
    user_details:null,
};

const slice = createSlice({
    name: 'signin',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // USER SIGNIN
        signIn(state, action) {
            state.isLoading = false;
            state.user_details = action.payload;
            state.user = action.payload;
            console.log("action",action)
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
   signIn,
    startLoading,
    hasError
} = slice.actions;

// ----------------------------------------------------------------------

export function  userSignIn(email,password) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await signin(email,password);
             if(response.code === 200){
                 dispatch(slice.actions.signIn(response.user_details));
                 dispatch(slice.actions.endLoading())
             }else{
                 dispatch(slice.actions.hasError(response.msg))
                 dispatch(slice.actions.endLoading())
             }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

