import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import {mainurl} from "../services";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  var url ="https://apifx.invict.site/api";

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);

          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
           const user = {name:"test user"};

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);



    const login =  async (email, password) => {
      try{
        const data =  await axios.post(mainurl, {
          code: 101,
          api: 110,
          data: { email: email, password: password },
        })
        if (data.data.code === 200) {
          const { operator_id} = data.data.user_details;
          setSession(operator_id);
          console.log('info',data.data);
          localStorage.setItem('userDetails', JSON.stringify(data.data.user_details));
          dispatch({
            type: 'LOGIN',
            payload: {
              user:data.data.user_details,
            },
          });
        } else {
          console.error('Sukker');
        }

        return data;

      }catch (e) {
        console.log(e);
      }

    };
  const register = async (email, password, phoneNumber, fullName) => {
    // axios
    //     .post('http://23.22.225.186/api', {
    //       code: 100,
    //       api: 100,
    //       data: {
    //         full_name: fullName,
    //         email: email,
    //         password: password,
    //       },
    //     })
    //     .then(function (response) {
    //       console.log(response.data.user_details);
    //       if (response.data.code === 200) {
    //         const { user_id } = response.data.user_details;
    //          setSession(user_id)
    //         window.localStorage.setItem('accessToken', user_id);
    //         dispatch(
    //             {
    //           type: 'REGISTER',
    //           payload: {
    //             user:user_id,
    //           },
    //         });
    //       } else {
    //         console.error('Sukker');
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
