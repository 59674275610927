// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_WEB = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    members:path(ROOTS_DASHBOARD,'/members'),
    newsLetter:path(ROOTS_DASHBOARD,'/news-letter'),
    tradeRoom:path(ROOTS_DASHBOARD,'/trade-room'),
    email:path(ROOTS_DASHBOARD,'/email'),
    notification:path(ROOTS_DASHBOARD,'/notification'),
    transactions:path(ROOTS_DASHBOARD,'/transactions'),
    // videos:path(ROOTS_DASHBOARD,'/videos'),
    ecommerce: path(ROOTS_DASHBOARD, '/packages'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  packages: {
    root: path(ROOTS_DASHBOARD, '/package'),
    add: path(ROOTS_DASHBOARD, '/packages/add'),
    all: path(ROOTS_DASHBOARD, '/packages/all'),

  },
  videos: {
    root: path(ROOTS_DASHBOARD, '/videos'),
    add: path(ROOTS_DASHBOARD, '/videos/add'),
    all: path(ROOTS_DASHBOARD, '/videos/all'),
    categories: path(ROOTS_DASHBOARD, '/videos/categories'),

  },

  affiliate: {
    root: path(ROOTS_DASHBOARD, '/affiliate'),
    influencers: path(ROOTS_DASHBOARD, '/affiliate/influencers'),
    referals: path(ROOTS_DASHBOARD, '/affiliate/referals'),

  },

  resource: {
    root: path(ROOTS_DASHBOARD, '/resources'),
    add: path(ROOTS_DASHBOARD, '/resources/add'),
    all: path(ROOTS_DASHBOARD, '/resources/all'),
  },

  users: {
    root: path(ROOTS_DASHBOARD, '/new-users'),
    technical: path(ROOTS_DASHBOARD, '/new-users/technical'),
    psychological: path(ROOTS_DASHBOARD, '/new-users/psychological'),
    trading_room: path(ROOTS_DASHBOARD, '/new-users/trading-room'),

  },
 courses: {
    root: path(ROOTS_DASHBOARD, '/courses'),
    add: path(ROOTS_DASHBOARD, '/courses/add'),
    all: path(ROOTS_DASHBOARD, '/courses/all'),
    assignments: path(ROOTS_DASHBOARD, '/courses/assignments'),

  },
  appointments: {
    root: path(ROOTS_DASHBOARD, '/appointment'),
    staffs: path(ROOTS_DASHBOARD, '/appointment/staffs'),
    users: path(ROOTS_DASHBOARD, '/appointment/users'),
    bookings: path(ROOTS_DASHBOARD, '/appointment/bookings'),


  },

  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
